import { useStaticQuery, graphql } from "gatsby";
import React from "react";
import loadable from "@loadable/component";
import "../../styles/blocks/news.scss";
const Parser = loadable(() => import("../parser.js"));

const Query = (props) => {
  const allPosts = useStaticQuery(graphql`
    query AllPosts {
      allWpPost(
        filter: {
          seo: {
            redirectUrl: {
              eq: ""
            }
          }
        }
        sort: { fields: [date], order: DESC }
        limit: 10
      ) {
        nodes {
          id
          excerpt
          uri
          title
          slug
          date
          categories {
            nodes {
              slug
              uri
              id
              name
            }
          }
          featuredImage {
            node {
              altText
              caption
              localFile {
                childImageSharp {
                  thumb: gatsbyImageData(width: 200)
                  small: gatsbyImageData(width: 400)
                  medium: gatsbyImageData(width: 800)
                  large: gatsbyImageData(width: 1000)
                  xl: gatsbyImageData(width: 1200)
                  full: gatsbyImageData
                }
              }
            }
          }
        }
      }
    }`);
  if (!props.attributes.variation) {
    return (<></>);
  }
  let posts = [];
  if (props.attributes.variation === "one-last-news") {
    posts.push(props.latestPost || allPosts.allWpPost.nodes[0]);
  }
  if (props.attributes.variation === "last-news") {
    try {
      posts.push(...props.allPosts);
    } catch {
      posts.push(...allPosts.allWpPost.nodes.slice(0, parseInt(props.attributes.query.perPage) || 3));
    }
  }
  const content = props.structure;
  const propsCopy = {...props};
  delete propsCopy.structure;
  //~ delete propsCopy.allPosts;
  //~ delete propsCopy.latestPost;
  delete propsCopy.attributes;
  delete propsCopy.terms;
  delete propsCopy.children;
  delete propsCopy.title;
  const className = [
    "news",
    props.attributes.variation,
    props.attributes.displayLayout?.type,
    "columns-"+props.attributes.displayLayout?.columns
  ];
  if (props.attributes.align) {
    className.push("align"+props.attributes.align);
  }
  const Wrapper = ({ children }) => {
    if (props.attributes.variation === "one-last-news") {
      return <div style={ props.style } className="one-last-news">{ children }</div>;
    }
    return (<section style={ props.style }
      className={ className.join(" ") }
    >
      { children }
    </section>);
  };
  return (<Wrapper displayLayout={ props.attributes.displayLayout } >
    <Parser content={ content } {...propsCopy} oneLastNews={props.attributes.variation === "one-last-news"} posts={ posts } />
  </Wrapper>);
};

export default Query;
